import authHeader from '../utils/authHeader';

const COHERENCE_API_URL =
  process.env.REACT_APP_COHERENCE_API_URL ||
  'https://coherent-summarization-service.staging.agolo.com';
const NODE_SUMM_API_URL =
  process.env.REACT_APP_NODE_SUMM_API_URL ||
  'https://node-sum.moodys.agolo.com';

const handleResponse = response => {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        localStorage.removeItem('user');
        window.location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
};

const summarizeWithCoherentSummarizer = ({ title, text }) => {
  const { Authorization } = authHeader();
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      articles: [
        {
          text
        }
      ],
      summary_length_words: 50
    })
  };

  return fetch(`${COHERENCE_API_URL}/summarization`, requestOptions)
    .then(handleResponse)
    .then(summary => summary);
};

const summarizeWithNodeSumSummarizer = ({ text, url, numberOfSentences }) => {
  const { Authorization } = authHeader();
  const article = text ? { text } : { url };
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization
    },
    body: JSON.stringify({
      articles: [article],
      summary_length: numberOfSentences
    })
  };

  return fetch(`${NODE_SUMM_API_URL}/summarization`, requestOptions)
    .then(handleResponse)
    .then(summary => summary);
};

export default {
  summarizeWithCoherentSummarizer,
  summarizeWithNodeSumSummarizer
};
