import React from 'react';
import { connect } from 'react-redux';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

import { userActions } from '../../redux/ducks/user';
import NewPasswordForm from './NewPasswordForm';
import BaseLayout from '../Layout/BaseLayout';

const NewPassword = props => (
  <BaseLayout
    selectedLink="2"
    children={
      <Row type="flex" justify="center" align="middle">
        <Col xs={22} sm={20} md={16} lg={8} xl={8}>
          <NewPasswordForm {...props} />
        </Col>
      </Row>
    }
  />
);

const mapStateToProps = state => {
  const { resetForm } = state.authentication;
  return {
    resetForm
  };
};

const mapDispatchToProps = dispatch => ({
  changePassword: ({ token, password }) =>
    dispatch(userActions.changePassword({ token, password }))
});

const NewPasswordPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPassword);
export default NewPasswordPageContainer;
