import React from 'react';
import { Link } from 'react-router-dom';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';

import 'antd/dist/antd.css';

const FormItem = Form.Item;

class ResetPasswordForm extends React.Component {
  static getDerivedStateFromProps({ resetForm, form }, state) {
    if (resetForm) {
      form.resetFields();
    }
    return state;
  }

  handleSubmit = e => {
    e.preventDefault();
    const { resetPassword, form } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        resetPassword({ email: values.email });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    return (
      <Form onSubmit={this.handleSubmit}>
        <legend>Reset Password</legend>
        <FormItem {...formItemLayout} label="E-mail">
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: 'The input is not valid E-mail!'
              },
              {
                required: true,
                message: 'Please input your E-mail!'
              }
            ]
          })(<Input />)}
        </FormItem>
        <FormItem {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            Reset Password
          </Button>
          <p>
            already have an account ? <Link to="/login">login!</Link>
          </p>
        </FormItem>
      </Form>
    );
  }
}

const WrappedResetPasswordForm = Form.create()(ResetPasswordForm);

export default WrappedResetPasswordForm;
