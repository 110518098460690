import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import message from 'antd/lib/message';
import Layout from 'antd/lib/layout';

import 'antd/dist/antd.css';
import './layout.scss';

const { Header, Content, Footer } = Layout;
const isLionTree = process.env.REACT_APP_ENV_NAME === 'liontree';
class BaseLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static getDerivedStateFromProps({ alert }, state) {
    switch (alert.type) {
      case 'success':
        message.success(alert.message, 3);
        break;
      case 'warning':
        message.warning(alert.message, 3);
        break;
      case 'error':
        message.error(alert.message, 3);
        break;
      case 'info':
        message.info(alert.message, 3);
        break;
      default:
        break;
    }
    return {};
  }
  render() {
    return (
      <Layout className="layout">
        <Header className="layout-header">
          <Link to="/">
            <div className="agolo-logo" />
          </Link>
          {isLionTree ? (
            <div className="liontree-logo" />
          ) : (
            <div className="ap-logo" />
          )}
          {this.props.navigation}
        </Header>
        <Content className="layout-content">{this.props.children}</Content>
        <Footer className="layout-footer" style={{ textAlign: 'center' }}>
          Agolo ©2018
        </Footer>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  const { alert } = state;
  return {
    alert
  };
};

const connectedBaseLayout = connect(mapStateToProps)(BaseLayout);
export default connectedBaseLayout;
