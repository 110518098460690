import { history } from '../../utils/history';
import getPublisherFromUrl from '../../utils/getPublisherFromUrl';
import { alertActions } from './alerts';
import summaryService from '../../services/summary';
import { userConstants } from './user';

export const summaryConstants = {
  SUMMARIZATION_REQUEST: 'USERS_SUMMARIZATION_REQUEST',
  SUMMARIZATION_SUCCESS: 'USERS_SUMMARIZATION_SUCCESS',
  SUMMARIZATION_FAILURE: 'USERS_SUMMARIZATION_FAILURE',
  SUMMARIZATION_CLEAR: 'USERS_SUMMARIZATION_CLEAR'
};

const summarizeWithCoherentSummarizer = ({ title, text, publisher }) => {
  return dispatch => {
    dispatch(request({ title, text, publisher }));
    summaryService.summarizeWithCoherentSummarizer({ title, text }).then(
      summary => {
        dispatch(
          success({
            ...summary,
            publisher:
              publisher && publisher.url && !publisher.name
                ? getPublisherFromUrl(publisher.url)
                : publisher
          })
        );
        history.push('/');
      },
      error => {
        dispatch(failure(error));
        dispatch(alertActions.error(error.message || error));
        dispatch(alertActions.clear());
      }
    );
  };
};

const summarizeWithNodeSumSummarizer = ({
  text,
  url,
  publisher,
  numberOfSentences
}) => {
  return dispatch => {
    dispatch(request({ publisher: { url }, numberOfSentences }));
    summaryService
      .summarizeWithNodeSumSummarizer({
        text,
        url,
        publisher,
        numberOfSentences
      })
      .then(
        summary => {
          const publisherObject =
            !publisher || !publisher.name
              ? getPublisherFromUrl(url)
              : publisher;
          dispatch(
            success({
              ...summary,
              publisher: publisherObject
            })
          );
          history.push('/');
        },
        error => {
          dispatch(failure(error));
          dispatch(alertActions.error(error.message || error));
          dispatch(alertActions.clear());
        }
      );
  };
};

const clearSummary = () => {
  return dispatch => {
    dispatch(newSummary());
  };
};

const request = ({ title, text, publisher, url, numberOfSentences }) => ({
  type: summaryConstants.SUMMARIZATION_REQUEST,
  title,
  text,
  publisher,
  url,
  numberOfSentences
});

const success = summary => ({
  type: summaryConstants.SUMMARIZATION_SUCCESS,
  summary
});

const failure = error => ({
  type: summaryConstants.SUMMARIZATION_FAILURE,
  error
});

const newSummary = () => ({
  type: summaryConstants.SUMMARIZATION_CLEAR
});

export const summaryActions = {
  summarizeWithCoherentSummarizer,
  summarizeWithNodeSumSummarizer,
  clearSummary
};

const initialState = {
  summarizationInProgress: false,
  title: '',
  text: '',
  summary: { sentences: [] }
};

export function summary(state = initialState, action) {
  switch (action.type) {
    case summaryConstants.SUMMARIZATION_REQUEST:
      return {
        summarizationInProgress: true,
        title: action.title,
        text: action.text
      };
    case summaryConstants.SUMMARIZATION_SUCCESS:
      return {
        summarizationInProgress: false,
        summary: action.summary
      };
    case summaryConstants.SUMMARIZATION_FAILURE:
      return {
        summarizationInProgress: false
      };
    case summaryConstants.SUMMARIZATION_CLEAR:
      return {
        title: '',
        text: ''
      };
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
}
