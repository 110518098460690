import React from 'react';
import { connect } from 'react-redux';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

import LoginForm from './LoginForm';
import BaseLayout from '../Layout/BaseLayout';
import { userActions } from '../../redux/ducks/user';
import { alertActions } from '../../redux/ducks/alerts';

const Login = props => (
  <BaseLayout
    selectedLink="2"
    children={
      <Row type="flex" justify="center" align="middle">
        <Col xs={22} sm={20} md={16} lg={10} xl={6}>
          <LoginForm {...props} />
        </Col>
      </Row>
    }
  />
);

const mapStateToProps = state => {
  const { loggingIn } = state.authentication;
  return {
    loggingIn
  };
};

const mapDispatchToProps = dispatch => ({
  verifyUser: ({ userId, token }) =>
    dispatch(userActions.verify({ userId, token })),
  errorAlert: err => dispatch(alertActions.error(err)),
  login: (email, password) => dispatch(userActions.login(email, password))
});

const connectedLoginPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
export default connectedLoginPage;
