import React, { Component } from 'react';
import { Radio } from 'antd';
import TextSummarizationForm from './TextSummarizationForm';
import UrlSummarizationForm from './UrlSummarizationForm';

class SummarizationWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      summarizer: 'url'
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = e => {
    e.preventDefault();
    if (e.target.value === 'text') {
      this.setState({
        summarizer: 'text'
      });
    } else {
      this.setState({
        summarizer: 'url'
      });
    }
  };

  render() {
    const { summarizer } = this.state;
    return (
      <div>
        <Radio.Group
          style={{ margin: '10px 0' }}
          value={summarizer}
          onChange={this.handleChange}
        >
          <Radio.Button value="url">URL</Radio.Button>
          <Radio.Button value="text">TEXT</Radio.Button>
        </Radio.Group>
        {summarizer === 'text' && <TextSummarizationForm {...this.props} />}
        {summarizer === 'url' && <UrlSummarizationForm {...this.props} />}
      </div>
    );
  }
}

export default SummarizationWrapper;
