import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import Form from 'antd/lib/form';
import Icon from 'antd/lib/icon';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Checkbox from 'antd/lib/checkbox';

import 'antd/dist/antd.css';
import './login.scss';
import { history } from '../../utils/history';

const FormItem = Form.Item;

class LoginForm extends Component {
  constructor(props) {
    super(props);

    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      history.push('/');
    }

    this.state = {
      email: '',
      password: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { verifyUser, errorAlert } = this.props;
    const values = queryString.parse(this.props.location.search);
    if (values.token) {
      try {
        let userId = JSON.parse(localStorage.getItem('user'));
        if (!userId && atob(values.token.split('.')[1])) {
          userId = JSON.parse(atob(values.token.split('.')[1])).sub;
        }
        verifyUser({ userId, token: values.token });
      } catch (error) {
        errorAlert('An Error Occured!');
      }
    }
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { login, form } = this.props;
    form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        const { email, password } = values;
        if (email && password) {
          login(email, password);
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loggingIn } = this.props;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <legend>Login</legend>
        <FormItem>
          {getFieldDecorator('email', {
            rules: [{ required: true, message: 'Please input your email!' }]
          })(
            <Input
              prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
              onChange={this.handleChange}
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }]
          })(
            <Input
              prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password"
              placeholder="Password"
              onChange={this.handleChange}
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('remember', {
            valuePropName: 'checked',
            initialValue: true
          })(<Checkbox>Remember me</Checkbox>)}
          <Link className="login-form-forgot" to="/reset-password">
            Forgot password
          </Link>
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={loggingIn}
            onClick={this.handleSubmit}
          >
            Log in
          </Button>
          Or <Link to="/register">register now!</Link>
        </FormItem>
      </Form>
    );
  }
}

const WrappedLoginForm = Form.create()(LoginForm);

export default WrappedLoginForm;
