import React, { Component } from 'react';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Select from 'antd/lib/select';
import Button from 'antd/lib/button';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

const FormItem = Form.Item;
const Option = Select.Option;

class TextSummarizationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: '',
      text: '',
      url: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  clearText = e => {
    const { setFieldsValue } = this.props.form;
    const { clearSummary } = this.props;
    setFieldsValue({ title: '', text: '' });
    clearSummary();
    this.setState({ text: '' });
  };

  handleSubmit = e => {
    e.preventDefault();
    const {
      summarizationAlgorithm,
      summarizeWithNodeSumSummarizer,
      summarizeWithCoherentSummarizer,
      form
    } = this.props;
    form.validateFields((err, values) => {
      const { title, text, url, name, numberOfSentences } = values;
      if (!err) {
        console.log('Received values of form: ', values);
        if (text) {
          const publisher = url ? { url, name } : null;
          if (summarizationAlgorithm === 'nodeSum') {
            summarizeWithNodeSumSummarizer({
              text,
              url,
              publisher,
              numberOfSentences
            });
          } else if (summarizationAlgorithm === 'coherent') {
            summarizeWithCoherentSummarizer({ title, text, publisher });
          }
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { showPublisherFields, summarizationInProgress } = this.props;
    const { text } = this.state;
    const formItemLayout = {
      labelCol: {
        lg: { span: 4 }
      },
      wrapperCol: {
        lg: { span: 3 }
      }
    };
    return (
      <Form onSubmit={this.handleSubmit} className="summarization-form">
        <FormItem>
          {getFieldDecorator('title')(
            <Input
              placeholder="short headline (optional)"
              onChange={this.handleChange}
            />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('text', {
            rules: [{ required: true, message: 'Please input some text!' }]
          })(
            <Input.TextArea
              name="text"
              placeholder="text of story to be summarized"
              onChange={this.handleChange}
              rows={8}
            />
          )}
        </FormItem>
        {showPublisherFields && (
          <FormItem>
            {getFieldDecorator('url')(
              <Input
                placeholder="article url (optional)"
                onChange={this.handleChange}
              />
            )}
          </FormItem>
        )}
        {showPublisherFields && (
          <FormItem>
            {getFieldDecorator('name')(
              <Input
                placeholder="publisher name (optional)"
                onChange={this.handleChange}
              />
            )}
          </FormItem>
        )}
        {showPublisherFields && (
          <FormItem {...formItemLayout} label="Sentences">
            {getFieldDecorator('numberOfSentences', {
              rules: [
                { required: true, message: 'Please select your country!' }
              ],
              initialValue: '3'
            })(
              <Select placeholder="Please select the number of sentences">
                <Option value="1">1</Option>
                <Option value="2">2</Option>
                <Option value="3">3</Option>
                <Option value="4">4</Option>
                <Option value="5">5</Option>
              </Select>
            )}
          </FormItem>
        )}
        <Row>
          {text && (
            <Col lg={{ span: 4, offset: 13 }}>
              <Button
                type="secondary"
                htmlType="submit"
                className="login-form-button"
                onClick={this.clearText}
              >
                New
              </Button>
            </Col>
          )}
          <Col lg={{ span: 6, offset: text ? 1 : 18 }}>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={summarizationInProgress}
              onClick={this.handleSubmit}
            >
              Summarize
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedTextSummarizationForm = Form.create()(TextSummarizationForm);

export default WrappedTextSummarizationForm;
