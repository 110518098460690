import React, { Component } from 'react';
import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import Input from 'antd/lib/input';
import Button from 'antd/lib/button';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

const FormItem = Form.Item;
const Option = Select.Option;
class UrlSummarizationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = e => {
    this.setState({ url: e.target.value });
  };

  clearText = e => {
    const { setFieldsValue } = this.props.form;
    const { clearSummary } = this.props;
    setFieldsValue({ url: '', numberOfSentences: 3 });
    clearSummary();
    this.setState({ url: '' });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { summarizeWithNodeSumSummarizer, form } = this.props;
    form.validateFields((err, values) => {
      const { url, numberOfSentences } = values;
      if (!err) {
        console.log('Received values of form: ', values);
        if (url) {
          summarizeWithNodeSumSummarizer({ url, numberOfSentences });
        }
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { summarizationInProgress } = this.props;
    const { url } = this.state;
    const formItemLayout = {
      labelCol: {
        lg: { span: 4 }
      },
      wrapperCol: {
        lg: { span: 3 }
      }
    };
    return (
      <Form onSubmit={this.handleSubmit} className="summarization-form">
        <FormItem>
          {getFieldDecorator('url', {
            rules: [
              {
                pattern: /http:\/\//.g
              },
              {
                required: true,
                message: 'Please input a valid url!'
              }
            ]
          })(<Input placeholder="Enter URL" onChange={this.handleChange} />)}
        </FormItem>
        <FormItem {...formItemLayout} label="Sentences">
          {getFieldDecorator('numberOfSentences', {
            rules: [
              {
                required: true,
                message: 'Please select the number of sentences!'
              }
            ],
            initialValue: '3'
          })(
            <Select>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
              <Option value="4">4</Option>
              <Option value="5">5</Option>
            </Select>
          )}
        </FormItem>
        <Row>
          {url && (
            <Col lg={{ span: 4, offset: 13 }}>
              <Button
                type="secondary"
                className="login-form-button"
                onClick={this.clearText}
              >
                New
              </Button>
            </Col>
          )}
          <Col lg={{ span: 6, offset: url ? 1 : 18 }}>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              loading={summarizationInProgress}
              onClick={this.handleSubmit}
            >
              Summarize
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

const WrappedUrlSummarizationForm = Form.create()(UrlSummarizationForm);

export default WrappedUrlSummarizationForm;
