const getPublisherFromUrl = url => {
  const parsed_url = {};

  if (!url || url.length === 0) return null;

  const remaining_url = url.substr(url.indexOf('://') + 3, url.length);
  let domain_i = remaining_url.indexOf('/');
  domain_i = domain_i === -1 ? remaining_url.length - 1 : domain_i;
  parsed_url.domain = remaining_url.substr(0, domain_i);
  parsed_url.path =
    domain_i === -1 || domain_i + 1 === remaining_url.length
      ? null
      : remaining_url.substr(domain_i + 1, remaining_url.length);

  let domain_parts = parsed_url.domain.split('.');
  switch (domain_parts.length) {
    case 2:
      parsed_url.host = domain_parts[0];
      break;
    case 3:
      parsed_url.host = domain_parts[1];
      break;
    case 4:
      parsed_url.host = domain_parts[1];
      break;
    default:
      break;
  }

  parsed_url.parent_domain = parsed_url.host + '.' + parsed_url.tld;

  return {
    name: parsed_url.host.toUpperCase(),
    url
  };
};

export default getPublisherFromUrl;
