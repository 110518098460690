import React from 'react';
import { connect } from 'react-redux';

import Home from './Home';
import MainLayout from '../Layout/MainLayout';
import { alertActions } from '../../redux/ducks/alerts';
import { summaryActions } from '../../redux/ducks/summary';
import { userActions } from '../../redux/ducks/user';

const HomePage = props => (
  <MainLayout
    logout={props.logout}
    selectedLink="1"
    children={<Home {...props} />}
  />
);

const mapStateToProps = state => {
  const { summary, summarizationInProgress } = state.summary;
  return {
    summary,
    summarizationInProgress
  };
};

const mapDispatchToProps = dispatch => ({
  clearAlerts: () => {
    setTimeout(() => {
      dispatch(alertActions.clear());
    }, 100);
  },
  errorAlert: err => dispatch(alertActions.error(err)),
  successAlert: msg => dispatch(alertActions.success(msg)),
  clearSummary: () => dispatch(summaryActions.clearSummary()),
  summarizeWithCoherentSummarizer: ({ title, text, publisher }) =>
    dispatch(
      summaryActions.summarizeWithCoherentSummarizer({ title, text, publisher })
    ),
  summarizeWithNodeSumSummarizer: ({
    text,
    url,
    publisher,
    numberOfSentences
  }) =>
    dispatch(
      summaryActions.summarizeWithNodeSumSummarizer({
        text,
        url,
        publisher,
        numberOfSentences
      })
    ),
  logout: () => dispatch(userActions.logout())
});

const HomeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HomePage);
export default HomeContainer;
