import React from 'react';
import { connect } from 'react-redux';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

import RegistrationForm from './RegistrationForm';
import BaseLayout from '../Layout/BaseLayout';
import { userActions } from '../../redux/ducks/user';

const Signup = props => (
  <BaseLayout
    selectedLink="2"
    children={
      <Row type="flex" justify="center" align="middle">
        <Col xs={22} sm={20} md={16} lg={12} xl={16}>
          <RegistrationForm {...props} />
        </Col>
      </Row>
    }
  />
);

const mapStateToProps = state => {
  const { signingUp, resetForm } = state.authentication;
  return {
    signingUp,
    resetForm
  };
};

const mapDispatchToProps = dispatch => ({
  register: ({ full_name, email, password }) =>
    dispatch(userActions.register({ full_name, email, password }))
});

const connectedSignupPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(Signup);
export default connectedSignupPage;
