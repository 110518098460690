import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import HomePage from '../Home';
import LoginPage from '../Login';
import SignupPage from '../Signup';
import ResetPasswordPage from '../ForgotPassword';
import NewPasswordPage from '../NewPassword';
import { PrivateRoute } from '../PrivateRoute';
import { alertActions } from '../../redux/ducks/alerts';
import { history } from '../../utils/history';

class App extends Component {
  constructor(props) {
    super(props);

    const { clearAlerts } = this.props;
    history.listen((location, action) => {
      // clear alert on location change
      clearAlerts();
    });
  }

  render() {
    return (
      <div className="jumbotron">
        <div className="container">
          <div className="col-sm-8 col-sm-offset-2">
            <Router history={history}>
              <div>
                <PrivateRoute exact path="/" component={HomePage} />
                <Route path="/login" component={LoginPage} />
                <Route path="/verify" component={LoginPage} />
                <Route path="/register" component={SignupPage} />
                <Route path="/reset-password" component={ResetPasswordPage} />
                <Route path="/reset" component={NewPasswordPage} />
              </div>
            </Router>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => ({
  clearAlerts: () => dispatch(alertActions.clear())
});

const connectedApp = connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
export default connectedApp;
