import { history } from '../../utils/history';
import { alertActions } from './alerts';
import userService from '../../services/user';

export const userConstants = {
  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
  SIGN_UP_REQUEST: 'USERS_SIGN_UP_REQUEST',
  SIGN_UP_SUCCESS: 'USERS_SIGN_UP_SUCCESS',
  SIGN_UP_FAILURE: 'USERS_SIGN_UP_FAILURE',
  CLEAR_FORM: 'USERS_CLEAR_FORM',
  VERIFY_USER_REQUEST: 'USERS_VERIFY_USER_REQUEST',
  VERIFY_USER_SUCCESS: 'USERS_VERIFY_USER_SUCCESS',
  VERIFY_USER_FAILURE: 'USERS_VERIFY_USER_FAILURE',
  PASSWORD_RESET_USER_REQUEST: 'USERS_PASSWORD_RESET_USER_REQUEST',
  PASSWORD_RESET_USER_SUCCESS: 'USERS_PASSWORD_RESET_USER_SUCCESS',
  PASSWORD_RESET_USER_FAILURE: 'USERS_PASSWORD_RESET_USER_FAILURE',
  PASSWORD_CHANGE_USER_REQUEST: 'USERS_PASSWORD_CHANGE_USER_REQUEST',
  PASSWORD_CHANGE_USER_SUCCESS: 'USERS_PASSWORD_CHANGE_USER_SUCCESS',
  PASSWORD_CHANGE_USER_FAILURE: 'USERS_PASSWORD_CHANGE_USER_FAILURE',
  LOGOUT: 'USERS_LOGOUT'
};

const login = (email, password) => {
  return dispatch => {
    dispatch(loginRequest({ email }));

    userService.login(email, password).then(
      user => {
        dispatch(loginSuccess(user));
        history.push('/');
      },
      error => {
        dispatch(loginFailure(error));
        dispatch(
          alertActions.error(
            error || 'email or password is incorrect, please try again!'
          )
        );
        dispatch(alertActions.clear());
      }
    );
  };
};

const register = ({ full_name, email, password }) => {
  return dispatch => {
    dispatch(signupRequest({ full_name, email }));

    userService.register({ full_name, email, password }).then(
      userId => {
        dispatch(signupSuccess(userId));
        dispatch(clearForm());
        dispatch(
          alertActions.success(
            'A verification link has been sent to your email!'
          )
        );
      },
      error => {
        dispatch(signupFailure(error));
        dispatch(
          alertActions.error(error || 'An Error occured, please try again!')
        );
        dispatch(alertActions.clear());
      }
    );
  };
};

const verify = ({ userId, token }) => {
  return dispatch => {
    dispatch(verificationRequest());

    userService.verify({ userId, token }).then(
      ({ verified }) => {
        if (verified) {
          dispatch(verificationSuccess());
          dispatch(alertActions.success('verification success, login now!'));
          dispatch(alertActions.clear());
        } else {
          dispatch(alertActions.error('verification error occured!'));
          dispatch(alertActions.clear());
        }
        history.push('/login');
      },
      error => {
        dispatch(verificationFailure(error));
        dispatch(
          alertActions.error(error || 'An Error occured, please try again!')
        );
        dispatch(alertActions.clear());
      }
    );
  };
};

const logout = () => {
  userService.logout();
  return { type: userConstants.LOGOUT };
};

const resetPassword = ({ email }) => {
  return dispatch => {
    dispatch(passwordResetRequest());
    userService.resetPassword({ email }).then(
      ({ success }) => {
        if (success) {
          dispatch(passwordResetSuccess());
          dispatch(alertActions.success('password reset email sent!'));
          dispatch(alertActions.clear());
          dispatch(clearForm());
        } else {
          dispatch(alertActions.error('password reset error occured!'));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(passwordResetFailure(error));
        dispatch(
          alertActions.error(error || 'An Error occured, please try again!')
        );
        dispatch(alertActions.clear());
      }
    );
  };
};

const changePassword = ({ token, password }) => {
  return dispatch => {
    dispatch(passwordChangeRequest());
    userService.changePassword({ token, password }).then(
      ({ success }) => {
        if (success) {
          dispatch(passwordChangeSuccess());
          dispatch(alertActions.success('password successfully changed!'));
          dispatch(alertActions.clear());
          dispatch(clearForm());
        } else {
          dispatch(alertActions.error('password change error occured!'));
          dispatch(alertActions.clear());
        }
      },
      error => {
        dispatch(passwordChangeFailure(error));
        dispatch(
          alertActions.error(error || 'An Error occured, please try again!')
        );
        dispatch(alertActions.clear());
      }
    );
  };
};

const loginRequest = user => ({ type: userConstants.LOGIN_REQUEST, user });
const loginSuccess = user => ({ type: userConstants.LOGIN_SUCCESS, user });
const loginFailure = error => ({ type: userConstants.LOGIN_FAILURE, error });

const verificationRequest = user => ({
  type: userConstants.VERIFY_USER_REQUEST
});
const verificationSuccess = user => ({
  type: userConstants.VERIFY_USER_SUCCESS
});
const verificationFailure = error => ({
  type: userConstants.VERIFY_USER_FAILURE,
  error
});

const passwordResetRequest = user => ({
  type: userConstants.PASSWORD_RESET_USER_REQUEST
});
const passwordResetSuccess = user => ({
  type: userConstants.PASSWORD_RESET_USER_SUCCESS
});
const passwordResetFailure = error => ({
  type: userConstants.PASSWORD_RESET_USER_FAILURE,
  error
});

const passwordChangeRequest = user => ({
  type: userConstants.PASSWORD_CHANGE_USER_REQUEST
});
const passwordChangeSuccess = user => ({
  type: userConstants.PASSWORD_CHANGE_USER_SUCCESS
});
const passwordChangeFailure = error => ({
  type: userConstants.PASSWORD_CHANGE_USER_FAILURE,
  error
});

const signupRequest = user => ({ type: userConstants.SIGN_UP_REQUEST, user });
const signupSuccess = userId => ({
  type: userConstants.SIGN_UP_SUCCESS,
  userId
});
const signupFailure = error => ({ type: userConstants.SIGN_UP_FAILURE, error });

const clearForm = () => ({ type: userConstants.CLEAR_FORM });

export const userActions = {
  register,
  verify,
  login,
  logout,
  clearForm,
  resetPassword,
  changePassword
};

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: true, user } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.SIGN_UP_REQUEST:
      return {
        signingUp: true
      };
    case userConstants.SIGN_UP_SUCCESS:
      return {
        user: { userId: action.userId }
      };
    case userConstants.LOGIN_FAILURE:
    case userConstants.SIGN_UP_FAILURE:
    case userConstants.VERIFY_USER_FAILURE:
    case userConstants.PASSWORD_RESET_USER_FAILURE:
    case userConstants.PASSWORD_CHANGE_USER_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};
    case userConstants.CLEAR_FORM:
      return { ...state, resetForm: true };
    default:
      return state;
  }
}
