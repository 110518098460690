const helpers = {
  fallbackCopyTextToClipboard(text, cb) {
    const textArea = document.createElement('textarea');
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      cb(!successful);
    } catch (err) {
      cb(err);
    }
    document.body.removeChild(textArea);
  },

  copyTextToClipboard(text, cb) {
    if (!navigator.clipboard) {
      helpers.fallbackCopyTextToClipboard(text, cb);
      return;
    }
    navigator.clipboard.writeText(text).then(
      () => {
        cb();
      },
      err => {
        cb(err);
      }
    );
  },

  copyRichTextToClipboard({ text, url, name }, cb) {
    return new Promise(function(resolve, reject) {
      var success = false;

      function listener(e) {
        e.clipboardData.setData('text/plain', text);
        e.clipboardData.setData(
          'text/html',
          `<p>${text} (<a href=${url}>${name}</a>)</p>
          `
        );
        e.preventDefault();
        success = true;
      }
      document.addEventListener('copy', listener);
      document.execCommand('copy');
      document.removeEventListener('copy', listener);
      if (success) {
        cb();
        resolve();
      } else {
        cb('An Error Occured!');
        reject();
      }
    });
  }
};

export default helpers;
