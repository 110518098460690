import { combineReducers } from 'redux';
import { alert } from './ducks/alerts';
import { authentication } from './ducks/user';
import { summary } from './ducks/summary';

const appReducer = combineReducers({
  authentication,
  alert,
  summary
});

export default appReducer;
