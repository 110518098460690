import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Menu from 'antd/lib/menu';
import Icon from 'antd/lib/icon';
import BaseLayout from './BaseLayout';

import 'antd/dist/antd.css';
import './layout.scss';

class MainLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.logout = this.logout.bind(this);
  }
  logout = () => {
    const { logout } = this.props;
    logout();
  };
  render() {
    return (
      <BaseLayout
        className="layout"
        navigation={
          <Menu
            className="layout-navigation"
            mode="horizontal"
            defaultSelectedKeys={[this.props.selectedLink]}
            style={{ lineHeight: '64px' }}
          >
            <Menu.Item className="pull-right" key="2">
              <Link onClick={this.logout} to="/login">
                <Icon type="logout" theme="outlined" />
                <span className="link-text">Logout</span>
              </Link>
            </Menu.Item>
          </Menu>
        }
        children={this.props.children}
      />
    );
  }
}

export default MainLayout;
