import React, { Component } from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Button from 'antd/lib/button';
import TextSummarizationForm from './TextSummarizationForm';
import SummarizationWrapper from './SummarizationWrapper';
import helpers from '../../utils/helpers';

const isLionTree = process.env.REACT_APP_ENV_NAME === 'liontree';
const summarizationAlgorithm = isLionTree ? 'nodeSum' : 'coherent';

class Home extends Component {
  constructor() {
    super();
    this.copySummary = this.copySummary.bind(this);
  }

  copySummary() {
    const { summary, errorAlert, successAlert, clearAlerts } = this.props;
    let text = '';
    if (summary.publisher) {
      const { name, url } = summary.publisher;
      summary.sentences.map(sentence => {
        text += `${sentence} \n`;
        return sentence;
      });
      helpers.copyRichTextToClipboard({ text, name, url }, err => {
        if (err) {
          errorAlert('Failed to copy summary!');
        } else {
          successAlert('summary copied successfuly!');
        }
        clearAlerts();
      });
    } else {
      summary.sentences.map(sentence => {
        text += `${sentence} \n`;
        return sentence;
      });
      helpers.copyTextToClipboard(text, err => {
        if (err) {
          errorAlert('Failed to copy summary!');
        } else {
          successAlert('summary copied successfuly!');
        }
        clearAlerts();
      });
    }
  }
  render() {
    const { summary } = this.props;
    return (
      <Row type="flex" justify="center" align="top" gutter={16}>
        <Col xs={{ span: 24 }} md={{ span: 10, offset: 2 }}>
          {isLionTree ? (
            <SummarizationWrapper
              summarizationAlgorithm={summarizationAlgorithm}
              showPublisherFields={isLionTree}
              {...this.props}
            />
          ) : (
            <TextSummarizationForm
              summarizationAlgorithm={summarizationAlgorithm}
              {...this.props}
            />
          )}
        </Col>
        {summary && summary.sentences.length > 0 ? (
          <Col xs={{ span: 24 }} md={{ span: 10 }}>
            {/* <Row>
              <Col>
                <h3>{summary.title}</h3>
              </Col>
            </Row> */}
            <Row>
              <Col xs={{ span: 12 }}>
                <h3>Summary</h3>
              </Col>
              <Col lg={{ offset: 18 }}>
                <Button onClick={this.copySummary} icon="copy">
                  copy to clipboard
                </Button>
              </Col>
            </Row>
            <Row>
              <p>{summary.sentences.map(sentence => `${sentence} `)}</p>
              {summary.publisher && (
                <p>
                  (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={summary.publisher.url}
                  >
                    {summary.publisher.name}
                  </a>
                  )
                </p>
              )}
            </Row>
          </Col>
        ) : (
          <Col xs={{ span: 24 }} md={{ span: 10 }} />
        )}
      </Row>
    );
  }
}

export default Home;
