import { userConstants } from './user';

export const alertConstants = {
  SUCCESS: 'ALERT_SUCCESS',
  INFO: 'ALERT_INFO',
  WARNING: 'ALERT_WARNING',
  ERROR: 'ALERT_ERROR',
  CLEAR: 'ALERT_CLEAR'
};

const success = message => {
  return { type: alertConstants.SUCCESS, message };
};

const info = message => {
  return { type: alertConstants.INFO, message };
};

const warning = message => {
  return { type: alertConstants.WARNING, message };
};

const error = message => {
  return { type: alertConstants.ERROR, message };
};

const clear = () => {
  return { type: alertConstants.CLEAR };
};

export const alertActions = {
  success,
  info,
  warning,
  error,
  clear
};

export const alert = (state = {}, action) => {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'success',
        message: action.message
      };
    case alertConstants.INFO:
      return {
        type: 'info',
        message: action.message
      };
    case alertConstants.WARNING:
      return {
        type: 'warning',
        message: action.message
      };
    case alertConstants.ERROR:
      return {
        type: 'error',
        message: action.message
      };
    case alertConstants.CLEAR:
    case userConstants.LOGOUT:
      return {};
    default:
      return state;
  }
};
