const API_URL =
  process.env.REACT_APP_USER_MANAGEMENT_API_URL ||
  'https://feeds-api.staging.agolo.com/feeds/v2.0';

const login = (email, password) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email,
      password,
      password_type: 'plain'
    })
  };

  return fetch(`${API_URL}/login`, requestOptions)
    .then(handleResponse)
    .then(user => {
      // login successful if there's a jwt token in the response
      if (user.jwt) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('user', JSON.stringify(user));
      }

      return user;
    });
};

const register = ({ full_name, email, password }) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      full_name,
      email,
      password
    })
  };

  return fetch(`${API_URL}/users`, requestOptions)
    .then(handleResponse)
    .then(response => {
      const location = response.headers.get('location');
      if (location) {
        const userId = location.split('/').splice(-1, 1)[0];
        localStorage.setItem('userId', userId);
        return userId;
      }
      return response;
    });
};

const verify = ({ userId, token }) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      token
    })
  };

  return fetch(`${API_URL}/users/${userId}/verification`, requestOptions)
    .then(handleResponse)
    .then(response => {
      return { verified: response.ok };
    });
};

const resetPassword = ({ email }) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      email
    })
  };

  return fetch(`${API_URL}/users/reset-password-email`, requestOptions)
    .then(handleResponse)
    .then(response => {
      return { success: response.ok };
    });
};

const changePassword = ({ token, password }) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      token,
      new_password: password
    })
  };

  return fetch(`${API_URL}/users/reset-password`, requestOptions)
    .then(handleResponse)
    .then(response => {
      return { success: response.ok };
    });
};

const logout = () => {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
};

const handleResponse = response => {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data || response;
  });
};

export default {
  register,
  login,
  verify,
  logout,
  resetPassword,
  changePassword
};
