import React from 'react';
import { connect } from 'react-redux';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

import { userActions } from '../../redux/ducks/user';
import ResetPasswordForm from './ResetPasswordForm';
import BaseLayout from '../Layout/BaseLayout';

const ResetPassword = props => (
  <BaseLayout
    selectedLink="2"
    children={
      <Row type="flex" justify="center" align="middle">
        <Col xs={22} sm={20} md={16} lg={12} xl={16}>
          <ResetPasswordForm {...props} />
        </Col>
      </Row>
    }
  />
);

const mapStateToProps = state => {
  const { loggingIn, resetForm } = state.authentication;
  return {
    loggingIn,
    resetForm
  };
};

const mapDispatchToProps = dispatch => ({
  resetPassword: ({ email }) => dispatch(userActions.resetPassword({ email }))
});

const ResetPasswordPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
export default ResetPasswordPageContainer;
